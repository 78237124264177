import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, repeat, skipWhile, take, throwIfEmpty } from 'rxjs';
import AssetUtil from '../util/asset.util';
import { BaseApiService } from './base-api.service';
import { CacheService } from './cache.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class WalletService extends BaseApiService {

  public userEventsLocal = {}

  constructor(
    protected baseApi: HttpClient,
    protected router: Router,
    protected toastr: ToastrService,
    private _notificationService: NotificationService,
    private _cacheService: CacheService,
  ) {
    super(baseApi, router, toastr);
    this.userEventsLocal = this._cacheService.getCache('eventsNewStatus') || {}
  }

  addEventsNewsOptions(events): Observable<any> {
    return this.put(this.urlBaseApi + `/event/wid/${this.walletId}`, events);
  }

  deleteAllWaletData(): Observable<any> {
    return this.delete(this.urlBaseApi + `/carteira/wid/${this.walletId}/data`);
  }

  editInvestidor(object): Observable<any> {
    return this.put(this.urlBaseApi + `/carteira`, object);
  }

  createInvestidor(object): Observable<any> {
    return this.post(this.urlBaseApi + `/carteira`, object);
  }

  delInvestidor(object): Observable<any> {
    return this.delete(this.urlBaseApi + `/carteira`, object);
  }

  getWalletByWid(wids: any): Observable<any> {
    return this.get(this.urlBaseApi + `/carteira?wid=${wids.toString()}`);
  }

  calculateFullPortfolio(): Observable<any> {
    return this.put(this.urlBaseApi + `/carteira/calculate/wid/${this.walletId}`, '', false);
  }

  isFullPortfolioComplete(): Observable<any> {
    return this.http.get(this.urlBaseApi + `/carteira/calculate/wid/${this.walletId}`)
      .pipe(repeat({ delay: 6000, count: 90 }),
      skipWhile((res) => res == false),
      throwIfEmpty(),
      take(1))
  }

  calculateFullWallet(walletObject: any) {
    let object = this.calculateObjectWallet(walletObject)
    this._cacheService.setCache({ 'walletObject': object.result })
    return object.result
  }

  calculateObjectWallet(portfolio: any) {
    let totalResume: any = {
      investedTotal: 0,
      marketTotal: 0,
      dailyTotalEarn: 0,
      earnings: 0,
      trades: 0,
    }

    portfolio.forEach(asset => {

      asset.vlr_mercado = asset.qtd * asset.price * asset.fator_pts
      asset.lucro_total = asset.vlr_mercado - asset.vlr_investido
      asset.lucro_total_pct = (asset.lucro_total / Math.abs(asset.vlr_investido)) * 100

      asset.lucro_dia = asset.qtd * asset.change * asset.fator_pts
      asset.lucro_dia_pct = (asset.lucro_dia / (asset.vlr_mercado - asset.lucro_dia)) * 100
      if ((asset.lucro_dia > 0 && asset.lucro_dia_pct < 0) || (asset.lucro_dia < 0 && asset.lucro_dia_pct > 0)) {
        asset.lucro_dia_pct = asset.lucro_dia_pct * -1
      }

      totalResume.investedTotal += asset.vlr_investido
      totalResume.marketTotal += asset.vlr_mercado
      totalResume.dailyTotalEarn += asset.lucro_dia
      totalResume.earnings += asset.earnings
      totalResume.trades += asset.trades
    })

    return { result: portfolio, total: totalResume }
  }

  groupForeignCurrencyAssets(portfolio, groupForeignFxOrigin: boolean) {
    if (!groupForeignFxOrigin) {
      return portfolio
    }

    let arrayForeignCurrencyAssets = portfolio.filter(foreignCurrencyAsset => foreignCurrencyAsset.ativo.endsWith("_E"))

    arrayForeignCurrencyAssets.forEach(asset => {
      const subAsset = asset.ativo
      const originalAsset = AssetUtil.getOriginalAssetFromForeignBuy(subAsset)

      let mainAsset = portfolio.filter(filterAsset => (filterAsset.ativo == originalAsset) && (filterAsset.corretora == asset.corretora));
      if (mainAsset.length > 0) {
        mainAsset = mainAsset[0]
        mainAsset.pm_fx = ((asset.pm_fx * asset.qtd) + (mainAsset.pm_fx * mainAsset.qtd)) / (mainAsset.qtd + asset.qtd)
        mainAsset.pm_usd = ((asset.pm_usd * asset.qtd) + (mainAsset.pm_usd * mainAsset.qtd)) / (mainAsset.qtd + asset.qtd)
        mainAsset.qtd += asset.qtd
        mainAsset.vlr_investido += asset.vlr_investido
        mainAsset.vlr_mercado += asset.vlr_mercado
        mainAsset.lucro_total += asset.lucro_total
        mainAsset.lucro_total_pct = (mainAsset.lucro_total / Math.abs(mainAsset.vlr_investido)) * 100
        mainAsset.lucro_dia = mainAsset.qtd * mainAsset.change * mainAsset.fator_pts
        mainAsset.lucro_dia_pct = (mainAsset.lucro_dia / (mainAsset.vlr_mercado - mainAsset.lucro_dia)) * 100
        if ((mainAsset.lucro_dia > 0 && mainAsset.lucro_dia_pct < 0) || (mainAsset.lucro_dia < 0 && mainAsset.lucro_dia_pct > 0)) {
          mainAsset.lucro_dia_pct = mainAsset.lucro_dia_pct * -1
        }
        mainAsset.earnings += asset.earnings
        mainAsset.trades += asset.trades
        mainAsset.xirr = asset.xirr_grouped

        mainAsset.pm = mainAsset.vlr_investido / mainAsset.qtd
        portfolio = portfolio.filter(filterAsset => filterAsset.ativo != subAsset)
      } else {
        asset.ativo = originalAsset
      }
    });

    return portfolio
  }

  calcEventsNews(wallet) {
    wallet = wallet || this._cacheService.getCache('walletObject')
    const stockLastPriceLocal = JSON.parse(localStorage.getItem('stockData')) || {}
    this.userEventsLocal = this._cacheService.getCache('eventsNewStatus') || {}

    let result = {}

    for (let i in wallet) {
      result[wallet[i].market_cod] = {}

      let eventos = this.checkEvents(stockLastPriceLocal[wallet[i]?.market_cod]?.events?.stockDividends)
      let proventos = this.checkEvents(stockLastPriceLocal[wallet[i]?.market_cod]?.events?.cashDividends)
      let subscricao = this.checkEvents(stockLastPriceLocal[wallet[i]?.market_cod]?.events?.subscriptions)
      let noticias = this.checkNews(stockLastPriceLocal[wallet[i]?.market_cod]?.news)

      result[wallet[i].market_cod]['eventos'] = this.checkEventsConfigSelection('eventos') && eventos?.result?.length > 0
      result[wallet[i].market_cod]['proventos'] = this.checkEventsConfigSelection('proventos') && proventos?.result?.length > 0
      result[wallet[i].market_cod]['subscricao'] = this.checkEventsConfigSelection('subscricao') && subscricao?.result?.length > 0
      result[wallet[i].market_cod]['noticias'] = noticias?.result?.length > 0

      result[wallet[i].market_cod]['eventos_unreaded'] = eventos?.unreaded
      result[wallet[i].market_cod]['proventos_unreaded'] = proventos?.unreaded
      result[wallet[i].market_cod]['subscricao_unreaded'] = subscricao?.unreaded
      result[wallet[i].market_cod]['noticias_unreaded'] = noticias?.unreaded
    }

    this._cacheService.setCache({ 'eventsNewToShow': result })
    return result
  }

  checkNews(eventsNews) {
    let result = []
    let undone = []

    for (let i in eventsNews) {
      let eventNew = eventsNews[i]
      let eventType = this._notificationService.getNoticiaType(eventNew.title)
      let showNoticia = this.userEventsLocal?.[eventType]?.delete == false ? false : true

      if (this.userEventsLocal[eventNew.hash]?.delete || !showNoticia) { } else {
        result.push(eventNew)

        if (this.userEventsLocal[eventNew.hash]?.done == true) {
          undone.push(eventNew)
        }
      }
    }
    return { result, unreaded: result.length - undone.length }
  }

  checkEvents(eventsNews) {
    let result = []
    let undone = []

    for (let i in eventsNews) {
      let eventNew = eventsNews[i]
      if (this.userEventsLocal[eventNew.hash]?.delete) { } else {
        result.push(eventNew)

        if (this.userEventsLocal[eventNew.hash]?.done == true) {
          undone.push(eventNew)
        }
      }
    }
    return { result, unreaded: result.length - undone.length }
  }

  checkEventsConfigSelection(param) {
    if (this.userEventsLocal && this.userEventsLocal[param]?.delete == false) {
      return false
    }
    return true
  }

}
