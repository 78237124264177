import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import jspreadsheet from 'jspreadsheet-ce';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CacheService } from 'src/app/services/cache.service';
import { OperationsService } from 'src/app/services/operations.service';
import { OutrosService } from 'src/app/services/outros.service';
import { SummaryService } from 'src/app/services/summary.service';
import DateUtil from 'src/app/util/date-util';
import EventUtil from 'src/app/util/event.util';
import FilterUtil from 'src/app/util/filter-util';
import NumberUtil from 'src/app/util/number-util';
import StaticParams from 'src/app/util/static-params';
import StorageUtil from 'src/app/util/storage-util';
import { ModalConfirmationDialogComponent, modalConfirmationDialogOptions } from '../modal-confirmation-dialog/modal-confirmation-dialog.component';

export const modalOperationsOptions: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-operations' };

@Component({
  selector: 'app-modal-operations',
  templateUrl: './modal-operations.component.html',
  styleUrls: ['./modal-operations.component.scss'],
  standalone: false
})
export class ModalOperationsComponent {

  @ViewChild('spreadsheet') spreadsheet: ElementRef;

  public title = ''
  public closeText = ''
  public data: any = [{}];
  public table: any;
  public loading = false

  public slicedData = []
  public pagesDataLen = 0
  public saveDataPage = 0
  public isEditingRows = 0
  public isLoadingBackup: boolean = false
  public outrosAtivos = [];

  constructor(
    private _serviceOperations: OperationsService,
    private _toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private _summaryService: SummaryService,
    private _router: Router,
    private spinnerService: NgxSpinnerService,
    private _modalService: NgbModal,
    private _cacheService: CacheService,
    private _outrosService: OutrosService,
  ) { }

  ngOnInit(): void {
    this.title = this.title ? this.title : this.data[0]?.id ? "Editar Operações" : "Adicionar Operações"
    this.closeText = this.closeText || "Fechar"
    this.getOutros()

    this.isEditingRows = this.data[0]?.id ? 10 : 0
    this.data = this.removeCalculatedPropetys(this.data)

    this.slicedData = this.sliceIntoChunks(this.data, 5000)
    this.pagesDataLen = this.slicedData.length
  }

  sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  removeCalculatedPropetys(operacoes) {
    let result = []
    for (let i in operacoes) {
      let { ativo, corretora, date, evento, irrf, moeda, qtd, preco, taxas, observacao, id } = operacoes[i]
      if (this.isEditingRows) {
        result.push({ ativo, corretora, date, evento, irrf, moeda, qtd, preco, taxas, observacao, id })
      } else {
        result.push({ ativo, corretora, date, evento, irrf, moeda, qtd, preco, taxas, observacao })
      }
    }
    return FilterUtil.lodash().sortBy(result, ['ativo', 'corretora', 'date'])
  }

  ngAfterViewInit() {
    this.table = jspreadsheet(this.spreadsheet.nativeElement, {
      data: [],
      columns: this.getBrowserModel(),
      minDimensions: [
        this.data[0]?.length ? this.data[0]?.length : 10,
        this.isEditingRows ? this.data?.length : 15,
      ],
      allowManualInsertColumn: false,
      allowDeleteColumn: false,
      columnResize: true,
      allowManualInsertRow: this.isEditingRows ? false : true,
      allowDeleteRow: this.isEditingRows ? false : true,
    });
    this.table.setData(this.slicedData[this.saveDataPage])
  }

  getBrowserModel() {
    let result = []
    result = [
      {
        title: '*Ativo',
        width: 110,
        name: 'ativo',
      },
      {
        title: '*Data',
        width: 80,
        name: 'date',
        // type: "calendar",
        type: 'text',
      },
      {
        title: "*Evento",
        name: 'evento',
        type: "dropdown",
        width: 90,
        source: EventUtil.operationEvents(),
        autocomplete: true,
      },
      {
        title: 'Qtd.',
        width: 80,
        name: 'qtd',
        decimal: ',',
      },
      {
        title: 'Preço',
        width: 80,
        name: 'preco',
        decimal: ',',
      },
      {
        title: 'Taxas',
        width: 80,
        name: 'taxas',
        decimal: ',',
      },
      {
        title: '*Corretora',
        width: 100,
        name: 'corretora'
      },
      {
        title: 'IRRF',
        width: 80,
        name: 'irrf',
        decimal: ',',
      },
      {
        title: "Moeda",
        name: 'moeda',
        type: "dropdown",
        width: 80,
        source: Object.keys(StaticParams.currencySymbols),
        autocomplete: true,
      },
      {
        title: "Observação",
        name: 'observacao',
        type: "text",
        width: 100,
      },
    ]

    let browserName = StaticParams.fnBrowserDetect()
    if ('firefox,safari'.split(',').indexOf(browserName) >= 0) {
      result[1].type = "calendar"
      result[8].width = 90
      result[9].width = 90
    }
    else if ('chrome,opera'.split(',').indexOf(browserName) >= 0) {
      result[1].mask = 'dd/mm/yyyy'
    }

    if (this.isEditingRows) {
      result.push({
        title: "Id",
        width: 100,
        name: 'id',
        type: "hidden",
      })
    }

    return result
  }

  getOutros() {
    this.outrosAtivos = this._cacheService.getCache('outrosAtivos')
    if (!this.outrosAtivos) {
      this._outrosService.getOutros().subscribe({
        next: async (outrosData) => {
          this._cacheService.setCache({ 'outrosAtivos': outrosData })
          this.outrosAtivos = outrosData
        },
        error: (err) => { }
      })
    }
  }

  async save() {
    if (!StorageUtil.isValidInvestidorPlan()) {
      this._toastr.warning('Limite de investidores do plano atingido! Verifique seu plano em "Minha Conta"');
      this._router.navigate(['/alpha']);
      this.activeModal.close()
      return
    }

    const operations = this.isValidOperationsObject(this.spreadsheet.nativeElement.jexcel.getJson())
    const operationsGrouped = this.agruparOperacoes(operations.valid)

    // Mostra o modal informando as operações DT que estão com qtd iguais de compra e venda na mesma data.
    if (operationsGrouped.error.length > 0) {
      // Cria uma tabela HTML formatada para melhor visualização dos erros
      let msg = `<table style="width: 100%; border-collapse: collapse; margin-top: 10px;"><thead><tr style="background-color: #f8d7da;"><th style="padding: 8px; text-align: left; border: 1px solid #f5c6cb;">Erro de Daytrade</th></tr></thead><tbody>`;

      for (let i in operationsGrouped.error) {
        // Alterna cores das linhas para melhor legibilidade
        const rowColor = parseInt(i) % 2 === 0 ? '#fff3f5' : '#ffeaed';
        msg += `<tr style="background-color: ${rowColor};"><td style="padding: 8px; text-align: left; border: 1px solid #f5c6cb;">${operationsGrouped.error[i]}</td></tr>`;
      }

      msg += `</tbody></table>`;

      const modalRef = this._modalService.open(ModalConfirmationDialogComponent, modalConfirmationDialogOptions);
      modalRef.componentInstance.modalProps = {
        type: 'confirm',
        okText: 'Fechar',
        // cancelText: 'Não',
        message: `<div style="margin-bottom: 10px; font-weight: bold; color: #721c24;">Verifique as quantidades de compra e venda Daytrade:</div><br>${msg}`
      }
      return
    }

    if (operations.error.length == 0 && operations.valid.length > 0 && !this.isEditingRows) {
      if (operationsGrouped.operacoes.length < operations.valid.length) {

        const onlyGruppedOperations = operationsGrouped.operacoes.filter(objeto => objeto["agrupada"] === true)
        let groupedOpsTable = ''
        
        // Comportamento para telas grandes
        if (window.innerWidth >= 720) {
          // Cria uma tabela HTML formatada para melhor visualização
          groupedOpsTable = `<table style="width: 100%; border-collapse: collapse; margin-top: 10px;"><thead><tr style="background-color: #f2f2f2;"><th style="padding: 8px; text-align: center; border: 1px solid #ddd;">Ativo</th><th style="padding: 8px; text-align: center; border: 1px solid #ddd;">Corretora</th><th style="padding: 8px; text-align: center; border: 1px solid #ddd;">Data</th><th style="padding: 8px; text-align: center; border: 1px solid #ddd;">Evento</th><th style="padding: 8px; text-align: right; border: 1px solid #ddd;">Qtd</th><th style="padding: 8px; text-align: right; border: 1px solid #ddd;">Preço</th><th style="padding: 8px; text-align: right; border: 1px solid #ddd;">Taxas</th><th style="padding: 8px; text-align: right; border: 1px solid #ddd;">IRRF</th></tr></thead><tbody>`;

          for (let i in onlyGruppedOperations) {
            const op = onlyGruppedOperations[i];
            // Alterna cores das linhas para melhor legibilidade
            const rowColor = parseInt(i) % 2 === 0 ? '#ffffff' : '#f9f9f9';
            groupedOpsTable += `<tr style="background-color: ${rowColor};"><td style="padding: 6px; text-align: center; border: 1px solid #ddd;">${op.ativo}</td><td style="padding: 6px; text-align: center; border: 1px solid #ddd;">${op.corretora}</td><td style="padding: 6px; text-align: center; border: 1px solid #ddd;">${DateUtil.usStrDatetoBrStrDate(op.date.split('T')[0])}</td><td style="padding: 6px; text-align: center; border: 1px solid #ddd;">${op.evento}</td><td style="padding: 6px; text-align: right; border: 1px solid #ddd;">${op.qtd.toFixed(2)}</td><td style="padding: 6px; text-align: right; border: 1px solid #ddd;">${op.preco.toFixed(2)}</td><td style="padding: 6px; text-align: right; border: 1px solid #ddd;">${op.taxas.toFixed(2)}</td><td style="padding: 6px; text-align: right; border: 1px solid #ddd;">${op.irrf.toFixed(2)}</td></tr>`;
          }
        } else {
          // Cria uma tabela HTML formatada, com menos dados para mobile
          groupedOpsTable = `<table style="width: 100%; border-collapse: collapse; margin-top: 10px;"><thead><tr style="background-color: #f2f2f2;"><th style="padding: 8px; text-align: center; border: 1px solid #ddd;">Ativo</th><th style="padding: 8px; text-align: center; border: 1px solid #ddd;">Corretora</th><th style="padding: 8px; text-align: center; border: 1px solid #ddd;">Data</th><th style="padding: 8px; text-align: center; border: 1px solid #ddd;">Evento</th><th style="padding: 8px; text-align: right; border: 1px solid #ddd;">Qtd</th><th style="padding: 8px; text-align: right; border: 1px solid #ddd;">Preço</th></tr></thead><tbody>`;

          for (let i in onlyGruppedOperations) {
            const op = onlyGruppedOperations[i];
            // Alterna cores das linhas para melhor legibilidade
            const rowColor = parseInt(i) % 2 === 0 ? '#ffffff' : '#f9f9f9';
            groupedOpsTable += `<tr style="background-color: ${rowColor};"><td style="padding: 6px; text-align: center; border: 1px solid #ddd;">${op.ativo}</td><td style="padding: 6px; text-align: center; border: 1px solid #ddd;">${op.corretora}</td><td style="padding: 6px; text-align: center; border: 1px solid #ddd;">${DateUtil.usStrDatetoBrStrDate(op.date.split('T')[0])}</td><td style="padding: 6px; text-align: center; border: 1px solid #ddd;">${op.evento}</td><td style="padding: 6px; text-align: right; border: 1px solid #ddd;">${op.qtd.toFixed(2)}</td><td style="padding: 6px; text-align: right; border: 1px solid #ddd;">${op.preco.toFixed(2)}</td></tr>`;
          } 
        }
        

        groupedOpsTable += `</tbody></table>`;

        // Mostra o modal informando as operações que serão agrupadas.
        const modalRef = this._modalService.open(ModalConfirmationDialogComponent, modalConfirmationDialogOptions);
        modalRef.componentInstance.modalProps = {
          type: 'confirm',
          okText: 'Sim',
          cancelText: 'Não',
          message: `<div style="margin-bottom: 10px;">Algumas operações realizadas no mesmo dia foram agrupadas para fins de otimização, confira os agrupamentos efetuados:</div><br>${groupedOpsTable}`
        }
        modalRef.result.then((result) => {
          if (result.type) {
            this.enviarOperacoesParaApiUsuarios(operationsGrouped.operacoes)
          }
        })
      } else {
        this.enviarOperacoesParaApiUsuarios(operations.valid)
      }
    }
    else if (operations.error.length == 0 && operations.valid.length > 0 && this.isEditingRows) {
      this._toastr.info('Processando. Aguarde!')
      this._serviceOperations.editList(operations.valid).subscribe({
        next: (x) => {
          this._toastr.success('Operações editadas')
          this._summaryService.updateSummaryBehavior()
          this.loading = false
          this.spinnerService.hide()
          this.saveDataPage++
          if (this.saveDataPage == this.pagesDataLen) {
            this.activeModal.close(1)
          } else {
            this.table.setData(this.slicedData[this.saveDataPage])
          }
        },
        error: (err) => {
          this.loading = false
          this.spinnerService.hide()
        }
      })
    }
    else if (operations.valid.length == 0 && operations.error.length == 0) {
      this._toastr.error('Preencha os campos obrigatórios!');
      this.loading = false
      this.spinnerService.hide()
    } else {
      this.loading = false
      this.spinnerService.hide()
    }
  }

  enviarOperacoesParaApiUsuarios(operacoes) {
    this.loading = true
    this.spinnerService.show()
    this._toastr.info('Processando. Aguarde!')

    let calculate_historic_and_portfolio: boolean = true
    if (this.isLoadingBackup) {
      calculate_historic_and_portfolio = false
    }

    this._serviceOperations.add(operacoes, calculate_historic_and_portfolio).subscribe({
      next: (x) => {
        this._toastr.success('Operações adicionadas')
        if (!this.isLoadingBackup) {
          this._summaryService.updateSummaryBehavior()
        }

        this.loading = false
        this.spinnerService.hide()
        this.saveDataPage++
        if (this.saveDataPage == this.pagesDataLen) {
          this.activeModal.close(1)
        } else {
          this.table.setData(this.slicedData[this.saveDataPage])
        }
      },
      error: (err) => {
        this.loading = false
        this.spinnerService.hide()
      }
    })
  }

  isValidOperationsObject(operationsObject) {
    const result = {
      valid: [],
      error: [],
    }
    for (let i in operationsObject) {
      let reqFields = operationsObject[i].ativo && operationsObject[i].date && operationsObject[i].corretora
      let row = this.isValidOperationRow(operationsObject[i])
      if (!row.error && reqFields) {
        result.valid.push(row)
      } else if (row.error && reqFields) {
        result.error.push(operationsObject[i])
        this._toastr.error(`Erro na Linha [${parseInt(i) + 1}]: ${row.error}`);
      }
    }
    return result
  }

  isValidOperationRow(operationRow) {
    let reqFields = operationRow.ativo && operationRow.date && operationRow.corretora
    let today = new Date();
    today.setMonth(today.getMonth() + 3);

    if (reqFields) {
      if (operationRow.preco == 'FATO.REL.' || operationRow.qtd == 'FATO.REL.') {
        return { error: 'verificar fato relevante' }
      }
      if (/[\x00-\x08\x0E-\x1F]/.test(operationRow.irrf)) {
        operationRow.irrf = 0
      }
      operationRow.ativo = operationRow.ativo.trim().toUpperCase().split('  ').join(' ')
      operationRow.corretora = StaticParams.verificaCorretora(operationRow.corretora)
      operationRow.moeda = operationRow.moeda || 'BRL';
      operationRow.qtd = NumberUtil.usFormat(operationRow.qtd)
      operationRow.preco = NumberUtil.usFormat(operationRow.preco)
      operationRow.taxas = NumberUtil.usFormat(operationRow.taxas)
      operationRow.irrf = NumberUtil.usFormat(operationRow.irrf)

      if (operationRow.ativo.indexOf("EX.OP") >= 0) {
        return { error: 'Informe o cod do ativo objeto na EX.OP' }
      }

      const date = DateUtil.dateToString(operationRow.date)
      if (date) {
        operationRow.date = date
      } else {
        return { error: 'data inválida' }
      }

      if (new Date(date) > today || date < '2000-01-01') {
        return { error: 'data fora do limite' }
      }

      if (!operationRow.evento) {
        if (operationRow.qtd || operationRow.preco < 0) {
          operationRow.evento = "V"
        } else {
          operationRow.evento = "C"
        }
      }
      if (EventUtil.operationEvents().indexOf(operationRow.evento) < 0) {
        return { error: 'evento inválido' }
      }
      if (
        operationRow.qtd.toString() == 'NaN' ||
        operationRow.preco.toString() == 'NaN' ||
        operationRow.taxas.toString() == 'NaN' ||
        operationRow.irrf.toString() == 'NaN'
      ) {
        return { error: 'número inválido' }
      }
      if (Object.keys(StaticParams.currencySymbols).indexOf(operationRow.moeda) < 0) {
        return { error: 'moeda inválida' }
      }

      return operationRow
    } else {
      return {}
    }
  }

  agruparOperacoes(operacoes) {
    const grupos = {};
    const resultado = [];
    const ativosManuais = [];
    const outrasOperacoes = [];

    for (const ativo of this.outrosAtivos) { ativosManuais.push(ativo.ativo) }

    for (let operacao of operacoes) {
      const chave = `${operacao.ativo} ${operacao.date.split('T')[0]} ${operacao.evento} ${operacao.corretora} ${operacao.moeda}`;

      if (!grupos[chave]) {
        grupos[chave] = {
          ativo: operacao.ativo,
          date: operacao.date,
          evento: operacao.evento,
          corretora: operacao.corretora,
          moeda: operacao.moeda,
          observacao: operacao.observacao,
          compras: {
            qtd: 0,
            preco_total: 0,
            taxas: 0,
            irrf: 0,
            operacoes: 0
          },
          vendas: {
            qtd: 0,
            preco_total: 0,
            taxas: 0,
            irrf: 0,
            operacoes: 0
          }
        };
      }

      if (ativosManuais.includes(operacao.ativo)) {
        outrasOperacoes.push(operacao)
      } else if (operacao.evento === 'C' || operacao.evento === 'DT' && operacao.qtd > 0) {
        grupos[chave].compras.qtd += operacao.qtd;
        grupos[chave].compras.preco_total += operacao.preco * operacao.qtd;
        grupos[chave].compras.taxas += operacao.taxas;
        grupos[chave].compras.irrf += operacao.irrf;
        grupos[chave].compras.operacoes++;
      } else if (operacao.evento === 'V' || operacao.evento === 'DT' && operacao.qtd < 0) {
        grupos[chave].vendas.qtd += Math.abs(operacao.qtd);
        grupos[chave].vendas.preco_total += operacao.preco * Math.abs(operacao.qtd);
        grupos[chave].vendas.taxas += operacao.taxas;
        grupos[chave].vendas.irrf += operacao.irrf;
        grupos[chave].vendas.operacoes++;
      } else {
        outrasOperacoes.push(operacao)
      }
    }

    // Converte o objeto de grupos para o array de resultado
    for (const chave in grupos) {
      const grupo = grupos[chave];

      // Calcula preço médio para compras e vendas
      const preco_medio_compra = grupo.compras.qtd > 0
        ? grupo.compras.preco_total / grupo.compras.qtd
        : 0;
      const preco_medio_venda = grupo.vendas.qtd > 0
        ? grupo.vendas.preco_total / grupo.vendas.qtd
        : 0;

      // Cria dois objetos, um para compra e outro para venda, se existirem operações
      if (grupo.compras.operacoes > 0) {
        resultado.push({
          ativo: grupo.ativo,
          date: grupo.date,
          evento: grupo.evento,
          qtd: grupo.compras.qtd,
          preco: preco_medio_compra,
          taxas: grupo.compras.taxas,
          corretora: grupo.corretora,
          irrf: grupo.compras.irrf,
          moeda: grupo.moeda,
          observacao: grupo.compras.operacoes >= 2 ? grupo.observacao || 'Op. Agrupada' : grupo.observacao,
          agrupada: grupo.compras.operacoes >= 2
        });
      }
      if (grupo.vendas.operacoes > 0) {
        resultado.push({
          ativo: grupo.ativo,
          date: grupo.date,
          evento: grupo.evento,
          qtd: -grupo.vendas.qtd,  // Mantém a convenção de negativo para venda
          preco: preco_medio_venda,
          taxas: grupo.vendas.taxas,
          corretora: grupo.corretora,
          irrf: grupo.vendas.irrf,
          moeda: grupo.moeda,
          observacao: grupo.vendas.operacoes >= 2 ? grupo.observacao || 'Op. Agrupada' : grupo.observacao,
          agrupada: grupo.vendas.operacoes >= 2
        });
      }
    }

    const dayTradeError = this.verificarPosicaoDayTradeZerada(resultado)

    return {
      operacoes: FilterUtil.lodash().sortBy(resultado.concat(outrasOperacoes), ['ativo', 'corretora', 'date']),
      error: dayTradeError
    };
  }

  verificarPosicaoDayTradeZerada(operacoes) {
    const error = []; // Objeto para armazenar os resultados por ativo
    const operacoesPorAtivo = {};

    for (const operacao of operacoes) {
      const chave = `${operacao.ativo} ${operacao.date.split('T')[0]} ${operacao.evento} ${operacao.corretora} ${operacao.moeda}`;
      if (!operacoesPorAtivo[chave]) { operacoesPorAtivo[chave] = [] }
      if (operacao.evento == 'DT') { operacoesPorAtivo[chave].push(operacao) }
    }

    // Verifica a posição para cada ativo
    for (const chave in operacoesPorAtivo) {
      const operacoesDoAtivo = operacoesPorAtivo[chave];
      let qtd_compra = 0;
      let qtd_venda = 0;

      for (const operacao of operacoesDoAtivo) {
        if (operacao.qtd > 0) {
          qtd_compra += operacao.qtd;
        } else {
          qtd_venda += Math.abs(operacao.qtd);
        }
      }

      if (qtd_compra != qtd_venda) {
        error.push(`${chave}: qtd C ${qtd_compra} diferente qtd V -${qtd_venda}.`)
      }
    }

    return error; // Retorna os resultados para todos os ativos
  }

}
