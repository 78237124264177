/* src/app/components/modal-operations/modal-operations.component.scss */
ul {
  text-align: center;
}
::ng-deep .modal-operations .modal-dialog {
  max-width: 980px;
  font-size: 12px;
}
@media only screen and (min-width: 720px) {
  ::ng-deep app-modal-confirmation-dialog {
    width: 630px;
  }
}
/*# sourceMappingURL=modal-operations.component.css.map */
